import { ReplyOrderRequest } from '../../api/punchlist/orders/types'
import {
  ORDER_ITEM_STATUS,
  ORDER_STATUS,
  ORDER_STATUS_REDUCED,
  ORDER_FILE_TYPE,
  ORDER_ITEM_IMAGE_TYPE,
  FEEDBACK_TYPE,
  ORDER_TYPE,
} from '../../helpers'

/** TYPES **/
export const ordersTypes = {
  FETCH_ORDERS: 'FETCH_ORDERS',
  FETCH_ORDER: 'FETCH_ORDER',
  FETCH_ORDER_ITEMS: 'FETCH_ORDER_ITEMS',
  FETCH_ORDER_FILES: 'FETCH_ORDER_FILES',
  FETCH_ORDER_NOTES: 'FETCH_ORDER_NOTES',
  SET_ORDERS: 'SET_ORDERS',
  ADD_ORDERS: 'ADD_ORDERS',
  SET_ACTIVE_ORDERS: 'SET_ACTIVE_ORDERS',
  SET_COMPLETED_ORDERS: 'SET_COMPLETED_ORDERS',
  REMOVE_ORDER: 'REMOVE_ORDER',
  SET_ORDER_VALUE: 'SET_ORDER_VALUE',
  SET_ORDER_VALUES: 'SET_ORDER_VALUES',
  UPDATE_ORDER_STATE: 'UPDATE_ORDER_STATE',
  UPDATE_ORDER_ITEM: 'UPDATE_ORDER_ITEM',
  UPDATE_ORDER_ITEM_IMAGES: 'UPDATE_ORDER_ITEM_IMAGES',
  SET_ORDER_ITEM_VALUES: 'SET_ORDER_ITEM_VALUES',
  SEND_ORDER_FEEDBACK: 'SEND_ORDER_FEEDBACK',
  REPLY_TO_ORDER: 'REPLY_TO_ORDER',
  ASSIGN_ORDER: 'ASSIGN_ORDER',
}

/** ACTIONS **/
export interface FetchOrdersPayload {
  status?: ORDER_STATUS_REDUCED
  assigneeId?: string
  assignerId?: string
  reset: boolean
}

export interface FetchOrdersAction {
  type: typeof ordersTypes.FETCH_ORDERS
  payload: FetchOrdersPayload
  callback?: (succ: boolean, count: number) => void
}

export interface FetchOrderByIdAction {
  type: typeof ordersTypes.FETCH_ORDER
  payload: string
  callback?: (succ: boolean) => void
}

export interface FetchOrderItemsAction {
  type: typeof ordersTypes.FETCH_ORDER_ITEMS
  payload: string
  callback?: (succ: boolean) => void
}

export interface FetchOrderFilesAction {
  type: typeof ordersTypes.FETCH_ORDER_FILES
  payload: string
  callback?: (succ: boolean) => void
}

export interface FetchOrderNotesAction {
  type: typeof ordersTypes.FETCH_ORDER_NOTES
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetOrdersAction {
  type: typeof ordersTypes.SET_ORDERS
  payload: Order[]
}

export interface AddOrdersAction {
  type: typeof ordersTypes.ADD_ORDERS
  payload: Order[]
}

export interface SetActiveOrdersAction {
  type: typeof ordersTypes.SET_ACTIVE_ORDERS
  payload: Order[]
}

export interface SetCompletedOrdersAction {
  type: typeof ordersTypes.SET_COMPLETED_ORDERS
  payload: Order[]
}

export interface RemoveOrderAction {
  type: typeof ordersTypes.REMOVE_ORDER
  payload: string
}

export interface OrderValue {
  orderId: string
  attr: keyof Order
  value: any
}

export interface SetOrderValueAction {
  type: typeof ordersTypes.SET_ORDER_VALUE
  payload: OrderValue
}

export interface OrderValues {
  orderId: string
  values: Partial<Order>
}

export interface SetOrderValuesAction {
  type: typeof ordersTypes.SET_ORDER_VALUES
  payload: OrderValues
}

export type OrderState = 'none' | 'checkin' | 'complete' | 'submission'

export interface UpdateStatePayload {
  orderId: string
  orderState: OrderState
}

export interface UpdateOrderStateAction {
  type: typeof ordersTypes.UPDATE_ORDER_STATE
  payload: UpdateStatePayload
  callback?: (succ: boolean) => void
}

export interface UpdateOrderItemPayload {
  orderId: string
  itemId: string
  partialItem: Partial<OrderItem>
}

export interface UpdateOrderItemAction {
  type: typeof ordersTypes.UPDATE_ORDER_ITEM
  payload: UpdateOrderItemPayload
  callback?: (succ: boolean) => void
}

export interface UpdateOrderItemImagesPayload {
  orderId: string
  itemId: string
  list: Partial<ImageFile>[] | string[]
  opp: 'add' | 'remove'
}

export interface UpdateOrderItemImagesAction {
  type: typeof ordersTypes.UPDATE_ORDER_ITEM_IMAGES
  payload: UpdateOrderItemImagesPayload
  callback?: (succ: boolean) => void
}

export interface OrderItemValues {
  orderId: string
  itemId: string
  attrs: Partial<OrderItem>
}

export interface SetOrderItemValuesAction {
  type: typeof ordersTypes.SET_ORDER_ITEM_VALUES
  payload: OrderItemValues
}

export interface FeedbackPayload {
  orderId: string
  score: number
  callback?: () => void
}

export interface Feedback {
  userId: string
  resourceId: string
  resourceType: FEEDBACK_TYPE
  ratings: [
    {
      description?: string
      score: number
    }
  ]
  comments?: string
}

export interface SendOrderFeedbackAction {
  type: typeof ordersTypes.SEND_ORDER_FEEDBACK
  payload: FeedbackPayload
}

export interface ReplyOrderPayload {
  orderId: string
  reply: ReplyOrderRequest
}

export interface ReplyToOrderAction {
  type: typeof ordersTypes.REPLY_TO_ORDER
  payload: ReplyOrderPayload
  callback?: (succ: boolean) => void
}

export interface AssignOrderPayload {
  orderId: string
  vendorId: string
}

export interface AssignOrderAction {
  type: typeof ordersTypes.ASSIGN_ORDER
  payload: AssignOrderPayload
  callback?: (succ: boolean) => void
}

export type OrdersActions =
  | FetchOrdersAction
  | FetchOrderItemsAction
  | FetchOrderFilesAction
  | FetchOrderNotesAction
  | SetOrdersAction
  | SetActiveOrdersAction
  | SetCompletedOrdersAction
  | RemoveOrderAction
  | SetOrderValueAction
  | SetOrderValuesAction
  | UpdateOrderStateAction
  | UpdateOrderItemAction
  | UpdateOrderItemImagesAction
  | SetOrderItemValuesAction
  | SendOrderFeedbackAction
  | ReplyToOrderAction
  | AssignOrderAction

/** REDUCER **/
export interface OrderAddress {
  city: string
  state: string
  line_1: string
  line_2: string
  zipCode: string
  latitude: number
  longitude: number
}

export interface ImageFile {
  id: string
  name: string
  description: string
  fileType: ORDER_ITEM_IMAGE_TYPE
  fileUrl: string
  uploadedOn: Date
  uploadedBy: string
}

export interface OrderItem {
  orderId: string
  estimateId: string
  estimatePublicId: string
  itemId: string
  title: string
  inspectionReportReference: string
  quantity: number
  status: ORDER_ITEM_STATUS
  disclaimer: string
  inspectionReportNote: string
  requestedRepairNote: string
  punchlistTechNote: string
  fieldNote: string
  imageFiles: ImageFile[]
  siteVisitId: string
  estimatedPrice: number
  siteVisitNotes: string
  siteVisitComplete: boolean
  followupNotes: {
    note: string
    createdOn: number
    id: string
  }[]
}

export interface OrderFile {
  id: string
  name: string
  description: string
  fileType: ORDER_FILE_TYPE
  fileUrl: string
  uploadedOn: Date
  uploadedBy: string
}

export interface OrderNote {
  noteId: string
  orderId: string
  note: string
  createdBy: string
  createdOn: Date
}

export interface Incentive {
  name: string
  amount: number
  deadline: number
  notes: string
}

export interface Order {
  type: ORDER_TYPE
  orderId: string
  orderPublicId: string
  orderName: string
  statusType: ORDER_STATUS
  estimateId: string
  estimatePublicId: string
  startTimestamp: Date
  endTimestamp: Date
  bidPrice: number
  companyName: string
  companyContact: string
  address: OrderAddress
  scheduledStartTimestamp: number | null
  scheduledEndTimestamp: number | null
  checkedInTimestamp: number | null
  completedTimestamp: number | null

  statusTypeReduced: ORDER_STATUS_REDUCED

  incentives: Incentive[] | null
  incentiveTotalAmount: number | null
  incentiveStartDeadline: number | null

  items: OrderItem[]
  files: OrderFile[]
  notes: OrderNote[]

  assigneeId: string
  assigneeName: string
  checkedInBy: string
  assignedByName: string
  assignedByAddress: OrderAddress
  assignedByPicture: string
}

export interface OrdersType extends Array<Order> { }
