export const AUTH0_CODES = {
  INVALID_PASSWORD: 'invalid_password',
  INVALID_SIGNUP: 'invalid_signup'
}

export enum EMAIL_TYPE {
  PRIMARY = 'PRIMARY',
  CC = 'CC',
  BCC = 'BCC'
}

export enum PAGE_STATE {
  DATA = 'DATA',
  LOADING = 'LOADING',
  EMPTY = 'EMPTY',
  ERROR = 'ERROR'
}

export enum STATUS_COLOR {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  RED = 'RED',
  DEFAULT = 'DEFAULT'
}

export enum ORDER_TYPE {
  ORDER = 'ORDER',
  SITE_VISIT = 'SITE_VISIT'
}

export enum ORDER_STATUS_REDUCED {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  PARENT_REVIEW = 'PARENT_REVIEW'
}

export enum ORDER_REPLY {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED'
}

export enum ORDER_REJECT_TYPE {
  EXPIRED = 'EXPIRED',
  PRICE = 'PRICE',
  SCHEDULE = 'SCHEDULE',
  DISINTEREST = 'DISINTEREST',
  OTHER = 'OTHER'
}

export enum ORDER_STATUS {
  APPROVED = 'APPROVED',
  DISPATCHED = 'DISPATCHED',
  REJECTED = 'REJECTED',
  REVIEW = 'REVIEW',
  COMPLETE = 'COMPLETE',
  INVOICED = 'INVOICED',
  PARENT_REVIEW = 'PARENT_REVIEW',
  PAID = 'PAID'
}

export enum ORDER_ITEM_STATUS {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  QC_REJECTED = 'QC_REJECTED',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  APPROVED = 'APPROVED'
}

export enum VERIFICATION_STAGE {
  VERIFIED = 'Verified',
}

export const orderItemStatusColor = (status: ORDER_ITEM_STATUS): STATUS_COLOR => {
  switch (status) {
    case ORDER_ITEM_STATUS.ACCEPTED: return STATUS_COLOR.GREEN
    case ORDER_ITEM_STATUS.REJECTED: return STATUS_COLOR.RED
    case ORDER_ITEM_STATUS.QC_REJECTED: return STATUS_COLOR.RED
    case ORDER_ITEM_STATUS.PENDING: return STATUS_COLOR.YELLOW
    case ORDER_ITEM_STATUS.COMPLETE: return STATUS_COLOR.GREEN
    case ORDER_ITEM_STATUS.APPROVED: return STATUS_COLOR.GREEN
    default: return STATUS_COLOR.DEFAULT
  }
}

export enum ORDER_FILE_TYPE {
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  ADDENDUM = 'ADDENDUM',
  PRO_REPORT = 'PRO_REPORT'
}

export enum ORDER_ITEM_IMAGE_TYPE {
  INSPECTION_IMAGE = 'INSPECTION_IMAGE',
  PRO_IMAGE = 'PRO_IMAGE'
}

export enum LICENSE_TYPE {
  FORM_W9 = 'FORM_W9',
  IDENTIFICATION = 'IDENTIFICATION',
  TRADE_LICENSE = 'TRADE',
  OTHER = 'OTHER'
}

export enum INSURANCE_TYPE {
  WORKERS_COMPENSATION = 'WORKERS_COMPENSATION',
  CERTIFICATE_OF_INSURANCE = 'CERTIFICATE_OF_INSURANCE'
}

export enum REVIEW_STATUS {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REVIEWED = 'REVIEWED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  EXPIRED = 'EXPIRED'
}

export enum CHECK_STATUS {
  TODO = 'TODO',
  PENDING = 'PENDING',
  DONE = 'DONE',
  ERROR = 'ERROR'
}

export enum FEEDBACK_TYPE {
  ORDER = 'ORDER'
}

export const reviewStatusToCheckStatus = (reviewStatus: REVIEW_STATUS): CHECK_STATUS => {
  switch (reviewStatus) {
    case REVIEW_STATUS.DRAFT: return CHECK_STATUS.PENDING
    case REVIEW_STATUS.SUBMITTED: return CHECK_STATUS.PENDING
    case REVIEW_STATUS.REVIEWED: return CHECK_STATUS.PENDING
    case REVIEW_STATUS.APPROVED: return CHECK_STATUS.DONE
    case REVIEW_STATUS.DENIED: return CHECK_STATUS.ERROR
    case REVIEW_STATUS.EXPIRED: return CHECK_STATUS.ERROR
    default: return CHECK_STATUS.TODO
  }
}
